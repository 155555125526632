
































































import i18n from '@/setup/i18n'
import LandingSection from '@/components/LandingSection.vue'
import { computed, defineComponent, reactive } from '@vue/composition-api'
import EsrLogo from '@/components/EsrLogo.vue'

interface LandingSectionHeroSlideConfig {
  id: number
  title: string
  description: string
  imageSrc: string
  imageAlt: string
}

export default defineComponent({
  name: 'LandingSectionHero',

  components: {
    LandingSection,
    EsrLogo,
  },

  setup() {
    const swiperOptions = reactive({
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    const slides = computed<LandingSectionHeroSlideConfig[]>(() => {
      const list: LandingSectionHeroSlideConfig[] = []
      for (let index = 0; index < 3; index++) {
        const basei18nPath = `heroSlides.${index}`

        list.push({
          id: index,
          title: `${basei18nPath}.title`,
          description: `${basei18nPath}.description`,
          imageSrc: `@/assets/images/hero/${index}.jpg`,
          imageAlt: i18n.t(`${basei18nPath}.imageAlt`) as string,
        })
      }
      return list
    })

    return {
      swiperOptions,
      slides,
    }
  },
})
